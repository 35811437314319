.flow {
  width: 100%;
  height: 100%;
}

.flow__logo {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.flow__video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #d62434;
}

.flow__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #d62434;
}

.flow__indigo {
  position: absolute;
  z-index: 999;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  box-sizing: border-box;
  width: 300px;
  padding: 10px 20px;
}

.flow__indigo svg {
  width: 100%;
  height: auto;
}

.flow__social {
  position: absolute;
  z-index: 999;
  left: 50%;
  bottom: 20px;
  opacity: 0;
  transform: translate(-50%, -1em);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: appear 1s forwards 5s;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate(-50%, -1em);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.flow__social a {
  display: block;
  height: 30px;
  margin-right: 10px;
  transition: transform 0.4s ease;
}

.flow__social a:last-child {
  margin-right: 0;
}

.flow__social a:hover {
  transform: scale(1.3);
}

.flow__social svg {
  width: auto;
  height: 100%;
}


@media (min-width: 768px) {
  .header {

  }
}

@media (min-width: 1025px) {
  .flow__indigo {
    bottom: 80px;
  }

  .flow__social {
    bottom: 40px;
  }
}
