@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/open-sans-v15-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
    url('/fonts/open-sans-v15-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v15-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v15-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v15-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v15-latin_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/open-sans-v15-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/fonts/open-sans-v15-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v15-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v15-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v15-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v15-latin_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/open-sans-v15-latin_cyrillic-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('/fonts/open-sans-v15-latin_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v15-latin_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v15-latin_cyrillic-800.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v15-latin_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v15-latin_cyrillic-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

html,
body {
    width: 100%;
    height: 100%;
    /*background-color: var(--bg-color);*/
    /*color: var(--text-color);*/
    font-family: 'Open Sans', 'Helvetica', sans-serif;
}

#root {
    width: 100%;
    height: 100%;
}

.app {
    min-width: 320px;
    box-sizing: border-box;
}

.app__main {

}

@media (min-width: 768px) {
    .app {

    }
}

@media (min-width: 1025px) {
    .app {

    }
}
